import { ImageLoaderProps } from 'next/image'

/**
 * Loader for next/image images that generates Fastly Image Optimizer URLs.
 *
 * These URLs are used in the resulting `<img />` tag's `srcset` attribute for
 * responsive images.
 *
 * Docs for this property here: https://nextjs.org/docs/api-reference/next/image#loader
 *
 * Docs for the `srcset` attribute: https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images
 *
 * Docs for the Fastly-supported query parameters here: https://www.fastly.com/documentation/reference/io/
 */
const imageLoader = ({ src, quality, width }: ImageLoaderProps) => {
  return `${src}?width=${width}&quality=${quality || 75}&auto=webp`
}

export { imageLoader }

import React from 'react'
import cn from 'classnames'
import Image from 'next/image'
import { $TSFixMe } from 'types'
import { imageLoader } from 'utils/image'
import styles from './cardimage.module.scss'

const IMAGES_DIR = '/assets/recipe-generic'
const IMAGES_LENGTH = 15

const getRandomIndex = () => Math.floor(Math.random() * IMAGES_LENGTH + 1)

// Return a consistent index for a given id
const getRandomIndexById = (id: $TSFixMe) =>
  String(1 + (id % (IMAGES_LENGTH + 1)))

// Return a random image src to be used for cards
// that don't have images. If provided with an id,
// this function will return a consistent image src
// for that id, otherwise it will be randomized.
const getGenericImageFilename = (id: $TSFixMe) => {
  const randomIndex =
    !id || Number.isNaN(id) ? getRandomIndex() : getRandomIndexById(id)

  return `${IMAGES_DIR}/${randomIndex}.png`
}

interface CardImageProps {
  id?: number
  alt: string
  className?: string
  src?: string
}

const CardImage: React.FC<CardImageProps> = (props) => {
  let src

  if (props.src) {
    src = props.src
  } else {
    src = getGenericImageFilename(props.id)
  }

  return (
    <Image
      className={cn(styles.image, props.className)}
      src={src}
      alt={props.alt}
      fill
      sizes="100%"
      loader={imageLoader}
      style={{
        maxWidth: '100%',
        objectFit: 'cover',
      }}
    />
  )
}

export default CardImage
